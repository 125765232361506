<template>
  <div>
    <v-card class="pa-8" v-if="!loading">
      <header-content :pageTitle="'Log Email BNI GFX'" />

      <div class="d-flex">
        <v-text-field
          v-model="searchQuery"
          outlined
          rounded
          dense
          color="primary"
          label="Cari kata kunci disini"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
        <v-btn
          color="primary"
          rounded
          class="ml-4 white--text text-none"
          @click="search"
        >
          Search
        </v-btn>
      </div>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                ID
              </th>
              <th class="text-left">
                Tanggal Pengiriman
              </th>
              <th class="text-left">
                NIK
              </th>
              <th class="text-left">
                Email
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(log, i) in logs"
              :key="i"
              class="cursor-pointer"
              @click="toDetail(log.nik)"
            >
              <td>{{ log.id }}</td>
              <td>{{ convertDate(log.created_at) }}</td>
              <td>{{ log.nik }}</td>
              <td>{{ log.email }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-col cols="6" class="mx-auto">
        <v-pagination
          v-model="logsPagination.page"
          class="my-4"
          :length="logsPagination.totalPages"
          :total-visible="7"
          @input="toPage"
        ></v-pagination>
      </v-col>
    </v-card>
    <v-card class="pa-8" v-else>
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-card>
  </div>
</template>

<script>
import store from "../../store/index.js";
import converters from "../../helpers/converters.js";
import HeaderContent from "../../components/Header/HeaderContent.vue";

export default {
  components: { HeaderContent },

  data() {
    return {
      searchQuery: "",
      loading: true,
      isAllLoaded: false,
      logs: [],
      logsPagination: {},
    };
  },

  methods: {
    toDetail(item) {
      this.$router.push("/mail-logs-detail/" + item);
    },
    convertDate(date) {
      return converters.date(date);
    },
    async search() {
      this.loading = true;

      if (this.searchQuery) {
        await store
          .dispatch("bniGfx/searchMailLogs", this.searchQuery)
          .then((r) => {
            if (r) {
              this.logs = store.state.bniGfx.mailLogs.results;
              this.logsPagination = store.state.bniGfx.mailLogs.pagination;
              console.log(this.logs, "alog");
              this.loading = false;
            }
          });
      } else {
        await this.getMailLogs();
      }
    },
    async getMailLogs() {
      this.loading = true;

      await store.dispatch("bniGfx/fetchAllMailLogs", 1).then((r) => {
        if (r) {
          this.logs = store.state.bniGfx.mailLogs.results;
          this.logsPagination = store.state.bniGfx.mailLogs.pagination;
          console.log(this.logs, "log");
          this.loading = false;
        }
      });
    },
    async toPage(page) {
      this.loading = true;

      await store.dispatch("bniGfx/fetchAllMailLogs", page).then((r) => {
        if (r) {
          this.logs = store.state.bniGfx.mailLogs.results;
          this.logsPagination = store.state.bniGfx.mailLogs.pagination;
          console.log(this.logs, "log");
          this.loading = false;
        }
      });
    },
  },

  mounted() {
    this.getMailLogs();
  },
};
</script>
